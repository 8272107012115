<template>
    <div class="pages" v-loading="loading">
        <table-height-auto>
            <template slot="before">
                <el-form :inline="true" class="demo-form-inline">
                    <div>
                        <el-form-item label="订单编号">
                            <el-input v-model="search.orderNo" clearable placeholder="模糊搜索" style="width: 160px;"></el-input>
                        </el-form-item>
                        <el-form-item label="用户账号">
                            <el-input v-model="search.rentUserPhone" clearable placeholder="模糊搜索" style="width: 160px;"></el-input>
                        </el-form-item>
                        <el-form-item label="土地名称">
                            <el-input v-model="search.landName" clearable placeholder="模糊搜索" style="width: 160px;"></el-input>
                        </el-form-item>
                        <el-form-item label="土地编号">
                            <el-input v-model="search.landDistrictNiceName" clearable placeholder="模糊搜索" style="width: 160px;"></el-input>
                        </el-form-item>
                        <el-form-item label="联系方式">
                            <el-input v-model="search.mobile" clearable placeholder="精确搜索" style="width: 160px;"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" size="medium" @click="getList">查询</el-button>
                            <el-button type="primary" size="medium" @click="reset">重置</el-button>
                        </el-form-item>
                    </div>

                    <div>
                        <el-form-item label="租赁类型">
                            <el-select v-model="search.type" placeholder="" style="width: 160px;">
                                <el-option label="全部" value="" />
                                <el-option
                                    v-for="item in typeList"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="租赁套餐">
                            <el-select v-model="search.packageId" placeholder="" style="width: 160px;">
                                <el-option label="全部" value="" />
                                <el-option
                                    v-for="item in packageList"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                </el-form>
            </template>

            <template v-slot:table="row">
                <el-table v-if="row.height"  :height="row.heightText"  :data="tableData" style="width: 100%">
                    <el-table-column type="index" width="50" label="序号" />
                    <el-table-column prop="orderNo" label="订单编号" width="120" />
                    <el-table-column prop="rentUserPhone" label="用户账号" width="200" align="center">
                        <template #default="scope">
                            <el-image class="avatar" fit="contain" :src="scope.row.rentUserIcon || 'http://imgs.cunzhiliao.com/village-cloud-files/img/mine/defaultAvatar.png'" style="width: 30px;height: 30px;" />
                            {{ scope.row.rentUserPhone }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="landName" label="土地名称" width="120" />
                    <el-table-column prop="landCode" label="土地编码" show-overflow-tooltip width="120" />
                    <el-table-column prop="landDistrictNiceName" label="土地编号" width="120" />
                    <el-table-column prop="mobile" label="联系方式" width="120" />
                    <el-table-column prop="type" label="租赁类型" width="120">
                        <template #default="scope">
                            {{ scope.row.type == 1 ? '首租': scope.row.type == 2 ? '续租': '' }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="packageName" label="租赁套餐" width="120" />
                    <el-table-column prop="rentCycle" label="租赁周期" width="120">
                        <template #default="scope">
                            {{ scope.row.rentCycle == 1 ? '包年': '' }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="payPrice" label="支付金额" width="120" >
                        <template #default="scope">
                            <span v-if="scope.row.payPrice">¥{{ scope.row.payPrice }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="status" label="支付状态" width="120">
                        <template #default="scope">
                            <span>{{ scope.row.status === 0 ? '已取消': scope.row.status === 1 ? '未支付': '已支付' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="payTime" label="支付时间" width="160" />
                    <el-table-column prop="createTime" label="创建时间" width="160" />

                </el-table>
            </template>

            <template slot="after">
                <div class="paging-row">
                    <el-pagination
                        v-if="total"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="currentPage"
                        :page-sizes="[5, 10, 15, 20]"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="total">
                    </el-pagination>
                </div>
            </template>
        </table-height-auto>
        <el-dialog title="查看" append-to-body style="z-index: 5001;" class="my-dialog" :visible.sync="isVisible" width="800px">
            <el-carousel v-if="isVisible" height="430px" :autoplay="false" trigger="click" :initial-index="initialIndex">
                <!-- <el-carousel-item v-for="item in list" :key="item"> -->
                <el-image v-if="showData.type == 1" :src="showData.img" fit="contain" style="width: 760px;height: 430px;"  />
                <video  v-else-if="showData.type == 2" :src="showData.video" controls="controls" style="width: 760px;height: 430px;" >
                    您的浏览器不支持视频播放
                </video>
                <el-empty v-else :image-size="180" description="数据格式有误！" />
                <!-- </el-carousel-item> -->
            </el-carousel>
            <template #footer>
                <el-button type="primary" @click="isVisible = false">确定</el-button>
            </template>
        </el-dialog>
        <addSeed @closeAdd="closeAdd" :addDialogVisible="addDialogVisible" :currentRow="currentRow" />
    </div>
</template>

<script>
import addSeed from "./components/addSeed.vue";
import {URL} from '../../../config'

export default {
  name: 'landConfig',
  components: {
    addSeed,
  },
  data() {
    return {
        search: {
            orderNo: '',
            rentUserPhone: '',
            landName: '',
            landDistrictNiceName: '',
            mobile: '',
            type: '',
            packageId: '',
        },
        loading: false,
        isVisible: false,
        tableData: [
            // {landNo: "B区0001-小兰菜园子", address: "广东省深圳市宝安区西乡镇", villageName: "青山村", plantLandArea: 3, plantLandList: [{no: 1, seedName: "玉米", seedPicture: "http://aaa.com/aa.jpg"}, {no: 3, seedName: "辣椒", seedPicture: "http://aaa.com/aa.jpg"}, {no: 4, "seedName": "西瓜", seedPicture: "http://aaa.com/aa.jpg"}], regionAddress: "广东省-广州市-越秀区-北京街道", publishUserLogo: "http://qqq.com/abc.jpg", serviceTypeName: "浇水,施肥", serviceLandCount: 3, plantLandTotalCount: 20}
        ],
        currentPage: 1,
        total: 0,
        pageSize: 10,
        serviceTypeList: [],
        addDialogVisible: false,
        currentRow: {},
        packageList: [],
        typeList: [
            { label: '首租', value: 1 },
            { label: '续租', value: 2 }
        ],
    }
  },
  methods: {

    getList() {
        this.axios.post(URL.farm.farmLandrentorderPage, { pageNo: this.currentPage, pageSize: this.pageSize, ...this.search }).then(res=>{
            if(res.code === '0'){
                this.tableData = res.data.records
                this.total = res.data.total;
            }else{
                this.$message.error(res.msg);
            }
        })
    },

    edit(row) {
        this.addDialogVisible = true;
        this.currentRow = row;
    },

    closeAdd() {
      this.addDialogVisible = false;
    },

    reset () {
        this.search = {
            orderNo: '',
            rentUserPhone: '',
            landName: '',
            landDistrictNiceName: '',
            mobile: '',
            type: '',
            packageId: '',
        }
        this.getList()
    },

    getfarmRentPackagePage() {
        this.axios.post(URL.farm.farmRentPackagePage, { pageNo: 1, pageSize: 999, ...this.search }).then(res=>{
            if(res.code === '0'){
                this.packageList = res.data.records;
            }else{
                this.$message.error(res.msg);
            }
        })
    },

    handleSizeChange(val,type){
        this.pageSize = val;
        this.currentPage = 1;
        this.getList()
    },

    handleCurrentChange(val,type) {
        this.currentPage = val;
        this.getfarmRentPackagePage();
        this.getList();
    },

    getPackageList() {
        this.axios.post(URL.farm.farmRentPackagePage, { pageNo: 1, pageSize: 999 }).then(res=>{
            console.log(res);
          if(res.code === '0'){
            this.packageList = res.data.records;
          }else{
            this.$message.error(res.msg);
          }
       })
    },
  },
  mounted() {
    this.getPackageList();
    this.getList();
  },

}
</script>

<style lang="less" scoped>
.pages {
    .search {
        display: flex;
        justify-content: space-between;

    }

}

.avatar {
    width: 60px;
    height: 60px;
    border-radius: 10px;
}
</style>
